import React, { useState, useEffect } from "react";
import axios from "axios";
import Header from "./header";
import "bootstrap/dist/css/bootstrap.min.css";
import ElectricityRegistry from "./electricityRegistry";
import GasRegistry from "./gasRegistry";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { jwtDecode } from "jwt-decode";
import { CSSProperties } from "react";
import BounceLoader from "react-spinners/BounceLoader";
import { faL } from "@fortawesome/free-solid-svg-icons";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";

const sendQuoteDialogInitialState = {
  firstName: "",
  lastName: "",
  email: "",
  isApiLoading: false,
};
const PulsePriceCalculator = () => {
  const [competitorPPD, setCompetitorPPD] = useState("");

  const [icpNumber, setICPNumber] = useState("");
  const [gasICPNumber, setGasICPNumber] = useState("");

  const [icpEleError, setICPEleError] = useState(false);
  const [icpGasError, setICPGasError] = useState(false);

  const [quaError, setQuaError] = useState(false);

  const [utilityType, setUtilityType] = useState("");

  const [registryType, setRegistryType] = useState("");

  const [eleICPDetails, setEleICPDetails] = useState({});
  const [gasICPDetails, setGasICPDetails] = useState({});

  const [eleCorrectedICPDetails, setEleCorrectedICPDetails] = useState({});
  const [gasCorrectedICPDetails, setGasCorrectedICPDetails] = useState({});

  const [productList, setProductList] = useState([]);
  const [gasProductList, setGasProductList] = useState([]);

  const [userClassList, setUserClassList] = useState([]);
  const [gasUserClassList, setGasUserClassList] = useState([]);

  const [showElectricity, setShowElectricity] = useState(false);
  const [showGas, setShowGas] = useState(false);

  const [selectedProduct, setSelectedProduct] = useState({});
  const [selectGasProduct, setSelectedGasProduct] = useState({});

  const [selectedUserClass, setSelectedUserClass] = useState([]);
  const [selectedGasUserClass, setSelectedGasUserClass] = useState([]);

  const [gasRate, setGasRate] = useState({});
  const [eleRate, setEleRate] = useState({});

  const [pulseTotal, setPulseTotal] = useState(null);

  const [searchRegistryInProgress, setSearchRegistryInProgress] =
    useState(false);

  const [isTOU, setIsTOU] = useState(false);
  const [userProfile, setUserProfile] = useState([]);
  const [calculateInprogress, setCalculateInProgress] = useState(true);
  const [isSendQuoteButtonDisabled, setIsSendQuoteButtonDisabled] =
    useState(true);

  const [showSendQuoteModal, setShowSendQuoteModal] = useState(false);
  const [sendQuoteDialogState, setSendQuoteDialogState] = useState(
    sendQuoteDialogInitialState
  );

  const [sendQuoteToastState, setSendQuoteToastState] = useState({
    showSuccessToast: false,
    showErrorToast: false,
  });

  const isSendQuoteDialogSubmitButtonEnabled =
    sendQuoteDialogState.email !== "" &&
    sendQuoteDialogState.firstName !== "" &&
    sendQuoteDialogState.lastName !== "" &&
    !sendQuoteDialogState.isApiLoading;

  const isSelectedProductsRestricted =
    ((utilityType === "both" || utilityType === "electricity") &&
      !selectedProduct?.productCode) ||
    selectedProduct?.productCode?.toLowerCase()?.endsWith("sczr");

  const isSelectedGasProductRestricted =
    (utilityType === "both" || utilityType === "gas") &&
    (!selectGasProduct?.productCode ||
      selectGasProduct?.productCode?.toLowerCase()?.endsWith("sczr"));

  const showSendQuoteContainer = showElectricity || showGas;

  const [utilityChange, setUtilityChange] = useState(false);

  const [rateObj, setRateObject] = useState({});

  const [gasRateObj, setGasRateObj] = useState([]);

  const [isPromiseProduct, setIsPromiseProduct] = useState(false);

  const [showQuote, setShowQuote] = useState(false);
  const [accessToken, setAccessToken] = useState("");
  const [userData, setUserData] = useState(null);
  const [showErrorPage, setShowErrorPage] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  let [loading, setLoading] = useState(false);
  let [gasLoading, setGasLoading] = useState(false);
  let [calcLoading, setCalcLoading] = useState(false);

  const resetAll = (param) => {
    if (param !== "both" && utilityType !== "both") {
      setShowElectricity(false);
      setShowGas(false);
      setIsPromiseProduct(false);
      setICPEleError(false);
      setICPGasError(false);
      setIsTOU(false);
      setPulseTotal(null);
    }

    setSearchRegistryInProgress(false);
  };

  const setUtilityTypeHandler = (event) => {
    setUtilityType(event.target.value);
    setICPNumber("");
    setGasICPNumber("");
    resetAll(event.target.value);
    setShowElectricity(false);
    setShowGas(false);
  };

  const { instance, accounts } = useMsal();

  useEffect(() => {
    const request = {
      scopes: ["User.Read"],
      account: accounts[0],
    };

    const fetchUserData = async () => {
      try {
        const response = await instance.acquireTokenSilent(request);
        const accessToken = response.accessToken;

        const idToken = response.idToken;

        if (idToken) {
          // Decode the id_token
          const decodedToken = jwtDecode(idToken);
          // Set the user profile information from the decoded token
          setUserProfile(decodedToken);
        }

        // const graphResponse = await axios.get("https://graph.microsoft.com/v1.0/me", {
        //     headers: {
        //         Authorization: `Bearer ${accessToken}`
        //     }
        // });
        // console.log(graphResponse.data)
        // setAccessToken(accessToken);
        // setUserData(graphResponse.data);
      } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
          instance.acquireTokenPopup(request).then((response) => {
            const accessToken = response.accessToken;
            axios
              .get("https://graph.microsoft.com/v1.0/me", {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then((response) => {
                setUserData(response.data);
              });
          });
        } else {
          console.error(error);
        }
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    setShowQuote(false);
    setShowSendQuoteModal(false);
  }, [loading]);

  const config = JSON.stringify({
    headers: { Authorization: `Bearer ${accessToken}` },
  });

  const getFormattedICPNumber = (icpNumber) => {
    let formattedICP = icpNumber.toUpperCase().trim();

    let fullNumber = "000000000000000" + formattedICP;
    return fullNumber.substr(fullNumber.length - 15);
  };

  const SearchRegistry = (type) => {
    setLoading(true);
    if (icpNumber === "" && utilityType === "electricity") {
      setICPEleError(true);
      setLoading(false);
      return;
    }
    if (gasICPNumber === "" && utilityType === "gas") {
      setGasLoading(true);
      setICPGasError(true);
      setLoading(false);
      return;
    }
    if (utilityType === "both") {
      if (icpNumber === "" && type === "electricity") {
        setICPEleError(true);
        setLoading(false);
        return;
      }

      if (gasICPNumber === "" && type === "gas") {
        setICPGasError(true);
        setLoading(false);
        return;
      }
    }

    // userProfile.roles = ['D2D'];
    // userProfile.name = 'focus admin';
    setICPEleError(false);
    setICPGasError(false);
    setRegistryType(type);
    setSearchRegistryInProgress(true);
    const icpNumberReq = type === "electricity" ? icpNumber : gasICPNumber;
    const icpType = type === "electricity" ? "icps" : "icp";
    axios
      .get(
        "https://gidufi4du7.execute-api.ap-southeast-2.amazonaws.com/dev/" +
          type +
          "/" +
          icpType +
          "/" +
          getFormattedICPNumber(icpNumberReq) +
          "?source=I&userGroup=" +
          userProfile.roles[0],
        config
      )
      .then((response) => {
        setLoading(false);
        if (!response.data.isQuotable) {
          setShowErrorPage(true);
          setErrorMsg(response.data.message);
          return;
        }
        const icpDetail =
          type === "electricity"
            ? response.data.myICPDetail
            : response.data.myGasICPDetail;
        if (type === "electricity") {
          setEleICPDetails(icpDetail);
          setShowElectricity(true);
          setProductList(response.data.productList);
          setSelectedProduct(response.data.productList[0]);
          setIsPromiseProduct(
            response.data.productList[0].productDescription.indexOf(
              "Promise"
            ) >= 0
          );
          setIsTOU(response.data.productList[0].isTOUPlan === 1);
        } else if (type === "gas") {
          setGasICPDetails(icpDetail);
          setShowGas(true);
          setGasProductList(response.data.gasProductList);
          setSelectedGasProduct(response.data.gasProductList[0]);
        }
        if (
          response.data?.productList?.length === 0 ||
          response.data?.gasProductList?.length === 0
        ) {
          setErrorMsg(
            "Unable to retrieve products for Pricing. Please try again later..."
          );
          setShowErrorPage(true);
        }
      })
      .catch((e) => {
        setSearchRegistryInProgress(false);
        setShowErrorPage(true);
        setErrorMsg(
          e?.response?.data?.message === "ICP not found"
            ? "ICP not found"
            : "Internal Server Error"
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setRegistryType("electricity");
    if (registryType !== "") {
      getUserClass();
    }
  }, [selectedProduct]);

  useEffect(() => {
    setRegistryType("gas");
    if (registryType !== "") {
      getUserClass();
    }
  }, [selectGasProduct]);

  useEffect(() => {
    if (
      registryType !== "" &&
      (Object.keys(selectedProduct).length !== 0 ||
        Object.keys(selectGasProduct).length !== 0)
    ) {
      getRate();
    }
  }, [selectedUserClass, selectedGasUserClass]);

  const replaceNulls = (obj) => {
    for (const key in obj) {
      if (obj[key] === null) {
        obj[key] = ""; // Replace null with an empty string
      } else if (typeof obj[key] === "number") {
        obj[key] = obj[key].toString(); // Replace null with an empty string
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        replaceNulls(obj[key]); // Recurse if the property is an object
      }
    }
  };

  const getUserClass = () => {
    setLoading(true);
    // TBD after login integration
    let reqObj = {
      //quotedBy: registryType === 'electricity' ? "vEvBkoRUiqx6kxEz/GT4" : "KATHYP/GT4"
      quotedBy: userProfile?.name,
      //quotedBy: 'Focus Admin'
    };
    const productObj =
      registryType === "electricity" ? selectedProduct : selectGasProduct;
    const icpDetails =
      registryType === "electricity"
        ? eleICPDetails
        : JSON.parse(JSON.stringify(gasICPDetails));
    reqObj.rpid = productObj?.productCode?.split("-")[0];
    if (registryType === "electricity") {
      icpDetails.hasGas = false;
      icpDetails.userClass = "";
      icpDetails.productCode = productObj.productCode;
      if (typeof icpDetails.numberOfMeters === "number") {
        icpDetails.numberOfMeters = JSON.stringify(icpDetails.numberOfMeters);
      }
      icpDetails.unmeteredLoadTrader =
        icpDetails.unmeteredLoadTrader === null
          ? ""
          : icpDetails.unmeteredLoadTrader;
      icpDetails.installDetails =
        icpDetails.installDetails === null ? "" : icpDetails.installDetails;

      icpDetails.isPrepay = icpDetails.isPrePay === "true";
      delete icpDetails.isPrePay;
      setEleCorrectedICPDetails(icpDetails);
    } else if (registryType === "gas") {
      replaceNulls(icpDetails);
      //customer gas address
      delete icpDetails.customerGasAddress.propertyName;
      delete icpDetails.customerGasAddress.suburb;
      delete icpDetails.customerGasAddress.unit;

      //customer gas status
      //customer gas Network
      delete icpDetails.customerGasNetwork.installationDetail;
      icpDetails.customerGasNetwork.gasGate =
        icpDetails.customerGasNetwork.gasRate;
      delete icpDetails.customerGasNetwork.gasRate;
      //customer gas retailer
      //customer gas Metering
      icpDetails.customerGasMetering.advanced =
        icpDetails.customerGasMetering.advanced === "true";
      icpDetails.customerGasMetering.operatingAtNetworkPressure =
        icpDetails.customerGasMetering.OperatingAtNetworkPressure === "true";
      delete icpDetails.customerGasMetering.OperatingAtNetworkPressure;
      icpDetails.customerGasMetering.prepay =
        icpDetails.customerGasMetering.Prepay === "true";
      delete icpDetails.customerGasMetering.Prepay;
      icpDetails.customerGasMetering.registerReadingDigits =
        icpDetails.customerGasMetering.RegisterReadingDigits;
      delete icpDetails.customerGasMetering.RegisterReadingDigits;
      icpDetails.customerGasMetering.tou =
        icpDetails.customerGasMetering.tou === "true";
      icpDetails.customerGasMetering.typeStandard =
        icpDetails.customerGasMetering.typeStandard === "true";
      icpDetails.customerGasMetering.meteringPriceCategry =
        icpDetails.customerGasMetering.meteringPriceCategory;
      delete icpDetails.customerGasMetering.meteringPriceCategory;
      icpDetails.customerGasMetering.registerMultiplier =
        icpDetails.customerGasMetering.registerMultipier;
      delete icpDetails.customerGasMetering.registerMultipier;
      //customer gas pricing
      delete icpDetails.customerGasPricing.networkPriceDetails;

      //gas ICP
      icpDetails.gasICP = icpDetails.gasIcp;
      delete icpDetails.gasIcp;
      setGasCorrectedICPDetails(icpDetails);
    }
    reqObj[registryType === "electricity" ? "icpDetail" : "gasIcpDetail"] =
      icpDetails;

    axios
      .post(
        "https://gidufi4du7.execute-api.ap-southeast-2.amazonaws.com/dev/" +
          registryType +
          "/user-classes",
        JSON.stringify(reqObj),
        config
      )
      .then((response) => {
        setLoading(false);
        registryType === "electricity"
          ? setUserClassList(response.data)
          : setGasUserClassList(response.data);
        registryType === "electricity"
          ? setSelectedUserClass(response.data[0])
          : setSelectedGasUserClass(response.data[0]);
      })
      .catch((e) => {
        setSearchRegistryInProgress(false);
        setShowErrorPage(true);
        setLoading(false);
      });
  };

  const getRate = () => {
    setLoading(true);
    let callGetLoad = true;
    let reqObj = {
      //quotedBy: registryType === 'electricity' ? "vEvBkoRUiqx6kxEz/GT4" : "KATHYP/GT4",
      quotedBy: userProfile?.name,
      // quotedBy: 'Focus Admin'
    };

    const userClassClone = Object.assign(
      {},
      registryType === "electricity" ? selectedUserClass : selectedGasUserClass
    );
    const rateICPDetails = Object.assign(
      {},
      registryType === "electricity" ? eleICPDetails : gasCorrectedICPDetails
    );
    const selectGasProductObj = Object.assign({}, selectGasProduct);
    const selectProductObj = Object.assign({}, selectedProduct);
    if (registryType === "electricity") {
      rateICPDetails.userClass = userClassClone.userClassCode;
      rateICPDetails.advancedInfrastructure =
        rateICPDetails.advancedInfrastructure === "true";
      userClassClone.userId = userClassClone.userClassCode;
      delete userClassClone.userClassCode;
      userClassClone.userDescription = userClassClone.userClassDescription;
      delete userClassClone.userClassDescription;

      delete selectProductObj.isTOUPlan;
    } else if (registryType === "gas") {
      userClassClone.gasUserId = userClassClone.userClassCode;
      delete userClassClone.userClassCode;
      userClassClone.gasUserDescription = userClassClone.userClassDescription;
      delete userClassClone.userClassDescription;

      selectGasProductObj["gasProductCode"] = selectGasProduct.productCode;
      selectGasProductObj["gasProductDescription"] =
        selectGasProduct.productDescription;
      delete selectGasProductObj.productCode;
      delete selectGasProductObj.productDescription;
    }

    reqObj[registryType === "electricity" ? "icpDetail" : "gasIcpDetail"] =
      rateICPDetails;

    const userInfo = {
      productInfor:
        registryType === "electricity" ? selectProductObj : selectGasProduct,
      userInfor: userClassClone,
    };

    const gasUserInfo = {
      gasProductInfor:
        registryType === "electricity" ? selectProductObj : selectGasProductObj,
      gasUserInfor: userClassClone,
    };
    let productUserInfo =
      registryType === "electricity" ? userInfo : gasUserInfo;
    reqObj = { ...reqObj, ...productUserInfo };

    if (reqObj !== null && reqObj?.gasICPDetails) {
      if (Object.keys(reqObj?.gasICPDetails).length === 0) {
        callGetLoad = false;
      }
    }

    if (reqObj !== null && reqObj?.gasProductInfor) {
      if (
        Object.keys(reqObj?.gasProductInfor).length === 0 ||
        reqObj?.gasProductInfor?.gasProductCode === undefined
      ) {
        callGetLoad = false;
      }
    }

    if (reqObj !== null && reqObj?.gasUserInfor) {
      if (
        Object.keys(reqObj?.gasUserInfor).length === 0 ||
        reqObj?.gasUserInfor?.gasUserId === undefined
      ) {
        callGetLoad = false;
      }
    }
    if (callGetLoad) {
      axios
        .post(
          "https://gidufi4du7.execute-api.ap-southeast-2.amazonaws.com/dev/" +
            registryType +
            "/rates/load",
          JSON.stringify(reqObj),
          config
        )
        .then((response) => {
          setLoading(false);

          if (registryType === "electricity") {
            setLoading(false);
            setEleRate(response.data);
            const filteredQuotes = Object.values(response.data.myQuote);
            const newElectricity = {
              quotes: filteredQuotes,
              compEV: "0",
              pricePromiseParameterAdjustmentVariable: {
                ...response.data.pricePromiseParameterAdjustmentVariable,
              },
            };
            setRateObject(newElectricity);
          } else if (registryType === "gas") {
            setGasLoading(false);
            setGasRate(response.data);
            setGasRateObj(response.data.myGasQuote);
          }
          setSearchRegistryInProgress(false);
          setCalculateInProgress(false);
        })
        .catch((e) => {
          setLoading(false);
          setSearchRegistryInProgress(false);
          setShowErrorPage(true);
        });
    }
  };

  // Function to stringify a specific property
  const stringifyProperty = (arr, prop) => {
    return arr.map((obj) => ({
      ...obj,
      [prop]: String(obj[prop]),
    }));
  };

  const calculateTotal = () => {
    setIsSendQuoteButtonDisabled(true);
    setCalculateInProgress(true);
    setCalcLoading(true);
    setLoading(true);
    let quoteRateParameter = [
      {
        contentCode: "0",
        pulseRate: "0",
        quantity: "0",
        energyRate: "0",
      },
    ];
    let gasQuoteRateParameter = [
      {
        contentDesc: "gas quote desc",
        pulseRate: "0",
        quantity: "0",
      },
    ];
    if (utilityType === "electricity" || (utilityType === "both" && !isTOU)) {
      console.log("quotes", rateObj?.quotes);

      if (
        rateObj?.quotes.some(
          (item) =>
            (["FIXED", "CAPACITY", "CONGESTION"].includes(item.contentCode) ||
              item?.contentCode.startsWith("EG")) &&
            (item.quantity === "0" || item.quantity === "")
        )
      ) {
        setQuaError(true);
        setCalculateInProgress(false);
        setLoading(false);
        return;
      }
      if (
        isPromiseProduct &&
        rateObj?.quotes.some(
          (item) =>
            (["FIXED", "CAPACITY", "CONGESTION"].includes(item.contentCode) ||
              item?.contentCode.startsWith("EG")) &&
            (item.compRate === "0" ||
              item.compRate === "" ||
              item.compRate === undefined)
        )
      ) {
        setQuaError(true);
        setCalculateInProgress(false);
        setLoading(false);
        return;
      }
      if (
        parseFloat(rateObj.compEALevy) > 0.5 ||
        parseFloat(rateObj.compEALevy) < 0.0
      ) {
        setQuaError(true);
        setCalculateInProgress(false);
        setLoading(false);
        return;
      }

      quoteRateParameter = rateObj?.quotes?.map((quote) => {
        const quoteParam = {
          contentCode: quote.contentCode,
          pulseRate: `${quote.rate || 0}`,
          quantity: `${quote.quantity || 0}`,
          energyRate: `${quote.energyRate || 0}`,
        };
        if (isPromiseProduct) {
          console.log(quote.contentCode);
          quoteParam.competitorRate =
            quote.contentCode.startsWith("EG") && quote.rate < 0
              ? `-${quote.compRate || 0}`
              : `${quote.compRate || 0}`;
        }
        return quoteParam;
      });
    }
    if (utilityType === "gas" || utilityType === "both") {
      if (
        gasRateObj.some(
          (item) =>
            item.quantity === "0" ||
            item.quantity === "" ||
            item.quantity === undefined
        )
      ) {
        setQuaError(true);
        setCalculateInProgress(false);
        setLoading(false);
        return;
      }
      gasQuoteRateParameter = gasRateObj.map((quote) => {
        return {
          contentDesc: "gas quote desc",
          pulseRate: `${quote.rate}`,
          quantity: `${quote.quantity}`,
        };
      });
    }

    setQuaError(false);

    let calcType = "normal";
    let reqObj = {};

    const tmpIsPrePay = eleICPDetails.isPrePay === "true";

    delete eleICPDetails.isPrePay;

    eleICPDetails.isPrepay = tmpIsPrePay;

    replaceNulls(eleICPDetails);

    let calIcpDetails = Object.assign({}, eleICPDetails);
    calIcpDetails.userClass = selectedUserClass.userClassCode;
    calIcpDetails.hasGas =
      utilityType === "both" || utilityType === "gas" ? true : false;
    reqObj = {
      quotedBy: userProfile?.name,
      //"quotedBy": 'Focus Admin',
      competitorAfterPPD: "0",
      icpDetail: calIcpDetails,
      quoteRateParameter: quoteRateParameter,
      gasParameter: {
        gasQuoteRateParameter: gasQuoteRateParameter,
        competitorAfterPPD_Gas: "0",
      },
    };
    // quotedBy: 'Focus Admin'
    if (isPromiseProduct) {
      calcType = "promise";
      delete reqObj.competitorAfterPPD;
      reqObj.quotedBy = userProfile?.name;
      //reqObj.quotedBy = 'Focus Admin';
      reqObj.rpid = "12434";
      reqObj.quoteRateParameter = quoteRateParameter;

      console.log("eleRate", eleRate);
      console.log(
        "isCompetitorRateGSTInlcusive",
        eleRate.isCompetitorRateGSTInlcusive
      );
      reqObj.otherFields = {
        competitorAfterPPD: "0",
        competitorBeforePPD: "0",
        eaLevy: `${rateObj.compEV}`,
        gst: "15.000",
        isCompetitorRateGSTInlcusive: eleRate.isCompetitorRateGSTInlcusive,
        productCode: selectedProduct.productCode,
        userClass: selectedUserClass.userClassCode,
      };
    }

    axios
      .post(
        "https://gidufi4du7.execute-api.ap-southeast-2.amazonaws.com/dev/pricing/" +
          calcType +
          "/saving",
        JSON.stringify(reqObj),
        config
      )
      .then((response) => {
        setPulseTotal(response.data);
        setShowQuote(true);
        setIsSendQuoteButtonDisabled(false);
        setCalculateInProgress(false);
        setSearchRegistryInProgress(false);
        setCalcLoading(false);
        setLoading(false);
      })
      .catch((error) => {
        setShowErrorPage(true);
        setIsSendQuoteButtonDisabled(true);
        setCalcLoading(false);
        setLoading(false);
      });
  };

  const handleProductChange = (product) => {
    console.log("selected user class", selectedUserClass);
    setSelectedProduct(product);
    setIsPromiseProduct(product.productDescription.indexOf("Promise") >= 0);
    setPulseTotal(null);
    setRateObject(null);
    setIsTOU(product.isTOUPlan === 1);
  };
  const handleUserClassChange = (userClassCode) => {
    const userClassListArr = structuredClone(userClassList);
    setSelectedUserClass(
      userClassListArr.find(
        (userClass) => userClass.userClassCode === userClassCode
      )
    );
    setPulseTotal(null);
  };

  const handleGasProductChange = (product) => {
    setSelectedGasProduct(product);
    setPulseTotal(null);
  };
  const handleGasUserClassChange = (userClassCode) => {
    setSelectedGasUserClass(
      gasUserClassList.find(
        (userClass) => userClass.userClassCode === userClassCode
      )
    );
    setPulseTotal(null);
  };

  const handleEleICPNumber = (e) => {
    setICPNumber(e.target.value);
    if (utilityType !== "both") {
      setUtilityChange((prevState) => !prevState);
    }
    resetAll("electricity");
  };

  const handleGasICPNumber = (e) => {
    setGasICPNumber(e.target.value);
    if (utilityType !== "both") {
      setUtilityChange((prevState) => !prevState);
    }
    resetAll("gas");
  };

  const fixedDecimals = (param) =>
    param === undefined ? (param = "") : Number(param).toFixed(3);

  const getGrandTotalStatus = () => {
    if (showElectricity || showGas) {
      if (showElectricity && isTOU && utilityType === "both") {
        return true;
      } else if (showElectricity && isTOU) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const getPulseTotal = () => {
    let pulseTotalVal = 0;
    if (pulseTotal === null) return;
    if (!isPromiseProduct && utilityType === "electricity") {
      pulseTotalVal =
        pulseTotal?.pulseTotalGSTInclusive &&
        fixedDecimals(pulseTotal?.pulseTotalGSTInclusive);
    } else if (isPromiseProduct && utilityType === "electricity") {
      pulseTotalVal = fixedDecimals(
        pulseTotal?.pricePromisePulseResult
          ?.pulseTotalGSTInclusiveAfterAdjustment
      );
    } else if (utilityType === "gas") {
      pulseTotalVal =
        pulseTotal?.gasSaving?.pulseTotalGSTInclusive &&
        fixedDecimals(pulseTotal?.gasSaving?.pulseTotalGSTInclusive);
    } else if (utilityType === "both" && isPromiseProduct) {
      pulseTotalVal = fixedDecimals(
        Number(
          pulseTotal?.pricePromisePulseResult
            ?.pulseTotalGSTInclusiveAfterAdjustment
        ) + Number(pulseTotal.gasSaving.pulseTotalGSTInclusive)
      );
    } else if (utilityType === "both" && !isPromiseProduct) {
      pulseTotalVal = fixedDecimals(
        Number(pulseTotal?.pulseTotalGSTInclusive) +
          Number(pulseTotal.gasSaving.pulseTotalGSTInclusive)
      );
    }
    return Number(pulseTotalVal).toFixed(2);
  };

  const getCompetitorTotal = () => {
    if (pulseTotal === null) return;
    if (!isPromiseProduct) return competitorPPD;
    return fixedDecimals(
      pulseTotal?.pricePromiseCompetitorResult?.competitorTotalGSTInclusive
    );
  };

  const getSavings = () => {
    let savingVal = 0;
    if (pulseTotal === null) return;
    if (utilityType === "electricity") {
      savingVal = pulseTotal.savingValue;
    } else if (utilityType === "gas") {
      savingVal = pulseTotal?.gasSaving?.savingValue;
    } else if (utilityType === "both") {
      savingVal =
        Number(pulseTotal?.gasSaving.savingValue) +
        Number(pulseTotal.savingValue);
    }

    //  savingVal = savingVal - Number(competitorPPD);

    if (getPulseTotal() > Number(competitorPPD)) {
      return (
        "We are more expensive by: $" +
        Number(fixedDecimals(getPulseTotal() - Number(competitorPPD))).toFixed(
          2
        )
      );
    } else {
      return (
        "Savings ($):" +
        Number(fixedDecimals(getPulseTotal() - Number(competitorPPD))).toFixed(
          2
        )
      );
    }
  };

  const handleSendQuote = () => {
    let utility = "";
    if (utilityType == "electricity") {
      utility = "E";
    } else if (utilityType == "gas") {
      utility = "G";
    } else if (utilityType == "both") {
      utility = "EandG";
    }

    const pulseTotal = getPulseTotal();

    const requestBody = {
      quotedBy: userProfile?.name || "",
      firstName: sendQuoteDialogState.firstName,
      lastName: sendQuoteDialogState.lastName,
      emailTo: sendQuoteDialogState.email,
      pulseTotal: `${pulseTotal || ""}`,
      competitorTotal: `${competitorPPD || ""}`,
      saving: competitorPPD === "" ? "" : getSavings() || "",
      type: utility,
      installAddress:
        utility === "E"
          ? eleICPDetails?.address || ""
          : utility === "G"
          ? gasICPDetails?.address || ""
          : eleICPDetails?.address || "",
      electricity:
        utility === "E" || utility === "EandG"
          ? {
              icp: eleICPDetails.icp,
              productInfor: {
                productCode: selectedProduct.productCode,
                productDescription: selectedProduct.productDescription,
              },
              userClass: {
                userClassCode: selectedUserClass.userClassCode,
                userClassDescription: selectedUserClass.userClassDescription,
              },
              quoteRate: rateObj?.quotes?.map((quote) => {
                return {
                  contentDesc: quote.contentDesc,
                  rate:
                    isPromiseProduct && pulseTotal
                      ? `${quote.promiseProductRate || 0}`
                      : `${quote.rate || 0}`,
                  chargeDesc: isTOU ? quote.chargeDesc : undefined,
                };
              }),
            }
          : undefined,
      gas:
        utility === "G" || utility === "EandG"
          ? {
              icp: gasICPDetails.gasIcp,
              productInfor: {
                productCode: selectGasProduct.productCode,
                productDescription: selectGasProduct.productDescription,
              },
              userClass: {
                userClassCode: selectedGasUserClass.userClassCode,
                userClassDescription: selectedGasUserClass.userClassDescription,
              },
              quoteRate: gasRateObj?.map((quote) => {
                return {
                  contentDesc: quote.contentDesc,
                  rate: `${quote.rate || 0}`,
                };
              }),
            }
          : undefined,
    };

    console.log("requestBody", requestBody);

    setSendQuoteDialogState({
      ...sendQuoteDialogState,
      isApiLoading: true,
    });

    axios
      .post(
        "https://gidufi4du7.execute-api.ap-southeast-2.amazonaws.com/dev/send-quote",
        JSON.stringify(requestBody),
        config
      )
      .then((response) => {
        setShowSendQuoteModal(false);
        setSendQuoteDialogState(sendQuoteDialogInitialState);
        setSendQuoteToastState({
          showSuccessToast: true,
          showErrorToast: false,
        });
      })
      .catch((error) => {
        setShowSendQuoteModal(false);
        setSendQuoteDialogState(sendQuoteDialogInitialState);
        setSendQuoteToastState({
          showSuccessToast: false,
          showErrorToast: true,
        });
      });
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card shadow m-4 pulse-calculator">
            <Header />
            <div className="green-strip-bg p-2">
              <h4 className="ms-5">
                Pulse Price Calculator
                <img
                  src="icons/calculator.png"
                  alt="Pulse Energy"
                  className="calc ms-2"
                />
              </h4>
            </div>
            <div className="card-body m-5">
              <div className="card-body-w-30">
                <div className="form-group mb-3">
                  <label htmlFor="competitorPPD">Competitor Bill</label>
                  <input
                    type="number"
                    id="competitorPPD"
                    className="form-control"
                    min="0"
                    value={competitorPPD}
                    onChange={(e) => setCompetitorPPD(e.target.value)}
                  />
                </div>
                <div className="form-group mb-3">
                  <label htmlFor="utility">Please select a utility</label>
                  <select
                    id="utility"
                    className="form-control"
                    onChange={setUtilityTypeHandler}
                  >
                    <option value="">Select</option>
                    <option value="electricity">Electricity</option>
                    <option value="gas">Gas</option>
                    <option value="both">Electricity & Gas</option>
                  </select>
                </div>
                {utilityType !== "" && (
                  <>
                    {(utilityType === "electricity" ||
                      utilityType === "both") && (
                      <>
                        <div className="form-group mb-3">
                          <label htmlFor="ipcNumber">
                            Electricity ICP Number
                          </label>
                          <input
                            type="text"
                            id="ipcNumber"
                            className="form-control"
                            value={icpNumber}
                            style={{
                              border: icpEleError ? "1px solid red" : "",
                            }}
                            onChange={handleEleICPNumber}
                          />
                          {icpEleError && (
                            <span className="text-danger">
                              No ICP has been specified.
                            </span>
                          )}
                        </div>
                        <button
                          className="btn btn-success mb-2"
                          onClick={() => SearchRegistry("electricity")}
                          disabled={searchRegistryInProgress}
                        >
                          Search Registry
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
              {showElectricity && (
                <ElectricityRegistry
                  products={productList}
                  userClassList={userClassList}
                  icpDetails={eleICPDetails}
                  rate={eleRate}
                  compititorPPD={competitorPPD}
                  passProductData={handleProductChange}
                  passUserClassData={handleUserClassChange}
                  callCalculateTotal={calculateTotal}
                  pulseTotal={pulseTotal}
                  updateRateObj={setRateObject}
                  tou={isTOU}
                  qaError={quaError}
                  utilityChangeObj={utilityChange}
                  fixedDecimals={fixedDecimals}
                />
              )}
              <div className="card-body-w-30">
                {utilityType !== "" && (
                  <>
                    {(utilityType === "gas" || utilityType === "both") && (
                      <>
                        <div className="form-group mb-3">
                          <label htmlFor="ipcNumber">Gas ICP</label>
                          <input
                            type="text"
                            id="ipcNumber"
                            className="form-control"
                            value={gasICPNumber}
                            style={{
                              border: icpGasError ? "1px solid red" : "",
                            }}
                            onChange={handleGasICPNumber}
                          />
                          {icpGasError && (
                            <span className="text-danger">
                              No ICP has been specified.
                            </span>
                          )}
                        </div>
                        <button
                          className="btn btn-success"
                          onClick={() => SearchRegistry("gas")}
                          disabled={searchRegistryInProgress}
                        >
                          Search Gas Registry
                        </button>
                      </>
                    )}
                  </>
                )}
              </div>
              {showGas && (
                <GasRegistry
                  products={gasProductList}
                  userClassList={gasUserClassList}
                  icpDetails={gasICPDetails}
                  rate={gasRate}
                  compititorPPD={competitorPPD}
                  passProductData={handleGasProductChange}
                  passUserClassData={handleGasUserClassChange}
                  callCalculateTotal={calculateTotal}
                  pulseTotal={pulseTotal}
                  updateRateObj={setGasRateObj}
                  sendError={quaError}
                  utilityChangeObj={utilityChange}
                  qaError={quaError}
                  fixedDecimals={fixedDecimals}
                />
              )}
            </div>
          </div>
          {getGrandTotalStatus() && (
            <>
              <div className="card shadow mt-2">
                <div className="footer-head">
                  <h5>Grand Total</h5>
                </div>
                <div className="card-body">
                  <div className="row text-center card-header">
                    <div className="col-md-4 fw-bold">
                      Pulse Total ($): {getPulseTotal()}
                    </div>
                    <div className="col-md-3 fw-bold">
                      Competitor Total ($):{" "}
                      {competitorPPD === ""
                        ? ""
                        : Number(competitorPPD).toFixed(2)}
                    </div>
                    <div className="col-md-3 fw-bold">
                      {" "}
                      {competitorPPD === "" ? "" : getSavings()}
                    </div>
                  </div>
                  <div className="col-12 text-center my-4">
                    <button
                      className="btn btn-success btn-lg"
                      onClick={calculateTotal}
                      data-bs-toggle="modal"
                      data-bs-target="#quoteSuccessModal"
                      disabled={calculateInprogress}
                    >
                      Calculate Total
                    </button>
                  </div>
                </div>
              </div>

              <Modal show={showQuote}>
                <Modal.Header closeButton className="green-strip-bg">
                  <Modal.Title>Information</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p>Quote has been</p>
                  <p>Successfully Logged</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="secondary"
                    onClick={() => setShowQuote(false)}
                  >
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* <div className="modal" tabIndex="-1" id="quoteSuccessModal" style={{'display': showQuote ? 'block' : 'none'}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header green-strip-bg">
                                    <h5 className="modal-title">Information</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowQuote(false)}></button>
                                </div>
                                <div className="modal-body success-popup">
                                    <p>Quote has been</p>
                                    <p>Successfully Logged</p>
                                </div>
                            </div>
                        </div>
                    </div> */}
            </>
          )}

          {showSendQuoteContainer && (
            <>
              <div className="col-12 text-center my-4">
                <button
                  className="btn btn-success btn-lg"
                  onClick={() => setShowSendQuoteModal(true)}
                  data-bs-toggle="modal"
                  data-bs-target="#quoteSuccessModal"
                  disabled={
                    isSelectedGasProductRestricted ||
                    isSelectedProductsRestricted
                  }
                >
                  Send Quote
                </button>
              </div>

              <Modal show={showSendQuoteModal}>
                <Modal.Header closeButton className="green-strip-bg">
                  <Modal.Title>Send Quote</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                  <div
                    className="form-group mb-3"
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <label htmlFor="firstname">First Name</label>
                      <input
                        type="text"
                        id="firstname"
                        className="form-control"
                        value={sendQuoteDialogState.firstName}
                        onChange={(e) =>
                          setSendQuoteDialogState({
                            ...sendQuoteDialogState,
                            firstName: e.target.value,
                          })
                        }
                        style={{
                          border: icpGasError ? "1px solid red" : "",
                        }}
                      />
                    </div>

                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <label htmlFor="lastname">Last Name</label>
                      <input
                        type="text"
                        id="lastname"
                        value={sendQuoteDialogState.lastName}
                        onChange={(e) =>
                          setSendQuoteDialogState({
                            ...sendQuoteDialogState,
                            lastName: e.target.value,
                          })
                        }
                        className="form-control"
                        style={{
                          border: icpGasError ? "1px solid red" : "",
                        }}
                      />
                    </div>
                  </div>

                  <div className="form-group mb-3">
                    <label htmlFor="email">Email</label>
                    <input
                      type="email"
                      id="email"
                      value={sendQuoteDialogState.email}
                      onChange={(e) =>
                        setSendQuoteDialogState({
                          ...sendQuoteDialogState,
                          email: e.target.value,
                        })
                      }
                      className="form-control"
                      style={{
                        border: icpGasError ? "1px solid red" : "",
                      }}
                    />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    style={{
                      display: "inline-block",
                    }}
                    variant="secondary"
                    onClick={() => setShowSendQuoteModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{
                      display: "inline-block",
                    }}
                    variant="secondary"
                    className="btn btn-success"
                    disabled={
                      !isSendQuoteDialogSubmitButtonEnabled ||
                      sendQuoteDialogState.isApiLoading
                    }
                    onClick={() => {
                      handleSendQuote();
                      // setShowSendQuoteModal(false);
                    }}
                  >
                    Submit
                  </Button>
                </Modal.Footer>
              </Modal>

              <ToastContainer
                className="p-3"
                position="bottom-center"
                style={{ zIndex: 100, position: "fixed", top: "10px" }}
              >
                <Toast
                  delay={5000}
                  autohide
                  show={sendQuoteToastState.showSuccessToast}
                  bg="success"
                  onClose={() => {
                    setSendQuoteToastState({
                      showSuccessToast: false,
                      showErrorToast: false,
                    });
                  }}
                >
                  <Toast.Header>
                    <strong className="me-auto">Quote Sent Successfully</strong>
                  </Toast.Header>
                  <Toast.Body className="text-white">
                    Your Quote has been sent via email
                  </Toast.Body>
                </Toast>

                <Toast
                  delay={5000}
                  autohide
                  show={sendQuoteToastState.showErrorToast}
                  bg="danger"
                  onClose={() => {
                    setSendQuoteToastState({
                      showSuccessToast: false,
                      showErrorToast: false,
                    });
                  }}
                >
                  <Toast.Header>
                    <strong className="me-auto">Error</strong>
                  </Toast.Header>
                  <Toast.Body className="text-white">
                    Something went wrong. Please try again later
                  </Toast.Body>
                </Toast>
              </ToastContainer>
            </>
          )}
          {/* {showErrorPage && 
                <> */}

          <Modal show={showErrorPage}>
            <Modal.Header closeButton className="error-modal">
              <Modal.Title>Error</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {errorMsg === ""
                ? "Internal Server Error! Please try again"
                : errorMsg}
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setShowErrorPage(false)}
              >
                Close
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={loading}>
            <Modal.Header>
              <Modal.Title>Processing...</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <BounceLoader loading={loading} size={60} color="#dff4c9" />
            </Modal.Body>
            <Modal.Footer></Modal.Footer>
          </Modal>

          {/* 
                    <div className="modal fade show" tabindex="-1" id="quoteSuccessModal" style={{'display': showErrorPage ? 'block' : 'none'}}>
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header error-modal">
                                    <h5 className="modal-title">Error</h5>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setShowErrorPage(false)}></button>
                                </div>
                                <div className="modal-body success-popup">
                                    <p>{errorMsg === '' ? 'Internal Server Error! Please try again' : errorMsg}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </>} */}
        </div>
      </div>
      <footer className="text-center">
        <p>All Copyrights reserved by AuroPro.</p>
      </footer>
    </div>
  );
};

export default PulsePriceCalculator;
