import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./styles.css";
import { useMsal } from "@azure/msal-react";

function Login() {
  const { instance } = useMsal();
  async function login() {
    await instance.loginRedirect();
  }

  // login();

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 container container">
      <div className="mx-auto custom-container p-4 text-center login-box">
        <div className="row">
          <div className="text-center header-logo col-3">
            <img src="icons/logo.png" alt="Pulse Energy" className="logo" />
          </div>
          <div className="col-9 header-bold">
            <h2 className="text-center">LOGIN!</h2>
            {/* <p className="text-center">Please login to Continue</p> */}
          </div>
        </div>
        <hr className="hr-ruler" />
        <div>
          <form>
            {/* <div className="form-group">
              <div className="input-group">
                <input type="text" className="form-control" placeholder="Enter your Username" />
              </div>
            </div>
            <div className="form-group mt-4">
              <div className="input-group">
                
                <input type="password" className="form-control" placeholder="Enter your Password" />
              </div>
            </div>
            <div className="form-check mt-3 col-3">
              <input type="checkbox" className="form-check-input" id="rememberMe" />
              <label className="form-check-label" htmlFor="rememberMe">Remember Me</label>
            </div> */}
            <div className="d-grid gap-2">
              <button
                type="submit"
                className="btn btn-success btn-lg btn-block mt-5 fw-bolder"
                onClick={login}
              >
                LOGIN
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default Login;
