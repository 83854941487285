import React from "react";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from "@azure/msal-react";
import PulsePriceCalculator from "./pulseCalculator";
import Login from "./login";

const App = () => {
  return (
    <>
      <div className="app-container">
        <AuthenticatedTemplate>
          <PulsePriceCalculator />
          <div className="watermark">UAT</div>
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>
          <Login />
          <div className="watermark">UAT</div>
        </UnauthenticatedTemplate>
      </div>
    </>
  );
};

export default App;