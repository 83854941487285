import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import TariffTable from "./toj";

const ElectricityRegistry = (props) => {
  const [electricity, setElectricity] = useState({
    compEV: "",
  });

  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedUserClass, setSelectedUserClass] = useState("");

  const [quaError, setQuaError] = useState(false);
  const [kwError, setKwError] = useState(false);

  const [isPromiseProduct, setIsPromiseProduct] = useState(false);

  const [error, setError] = useState(false);
  const [lowerLMT, showLowerLMTError] = useState(false);
  const [upperLMT, showUpperLMTError] = useState(false);
  const [isProductOrUserClassChange, setIsProductOrUserClassChange] =
    useState(false);
  const [isSeasonal, setIsSeasonal] = useState(false);
  const [isTOUProd, setIsTOUProd] = useState(false);

  let pulseTotal =
    props.pulseTotal !== null ? Object.assign({}, props.pulseTotal) : null;

  const handleProduct = (productCode) => {
    pulseTotal = null;
    const product = props.products.find(
      (product) => product.productCode === productCode
    );
    setIsPromiseProduct(product.productDescription.indexOf("Promise") >= 0);
    setSelectedProduct(product.productDescription);
    setIsProductOrUserClassChange(true);
    setIsTOUProd(product.isTOUPlan === 1);
    props.passProductData(product);
  };

  const handleUserClass = (userClassCode) => {
    setIsProductOrUserClassChange(true);
    setSelectedUserClass(userClassCode);
    props.passUserClassData(userClassCode);
  };

  const handleQuantity = (quantity, idx) => {
    const quoteType = electricity.quotes[idx].contentCode;

    const isFixedQuoteTypeBeingUpdated = (quoteType === "FIXED") === true;

    const updateQuotes = electricity.quotes.map((quote) => {
      const isDependantOnFixedQuoteType =
        (quote.contentCode === "CAPACITY" ||
          quote.contentCode === "CONGESTION") === true;

      const updateQuantityForDependantQuoteType =
        isDependantOnFixedQuoteType && isFixedQuoteTypeBeingUpdated;

      if (
        quoteType === quote.contentCode ||
        updateQuantityForDependantQuoteType
      ) {
        return { ...quote, quantity: quantity };
      } else {
        return quote;
      }
    });
    const newElectricity = { ...electricity };
    newElectricity.quotes = updateQuotes;
    setElectricity(newElectricity);
    props.updateRateObj(newElectricity);
  };

  const handleCompRate = (rate, idx, quote) => {
    if (quote.contentCode !== "FIXED") {
      rate = Number(rate);
      const upperLMT = Number(
        electricity?.pricePromiseParameterAdjustmentVariable
          ?.varChargeUpperLimit
      );
      const lowerLMT = Number(
        electricity?.pricePromiseParameterAdjustmentVariable
          ?.varChargeLowerLimit
      );

      if (rate < lowerLMT) showLowerLMTError(true);
      if (rate > upperLMT) showUpperLMTError(true);
      if (rate >= lowerLMT && rate <= upperLMT) {
        showLowerLMTError(false);
        showUpperLMTError(false);
      }
      if (rate === 0) {
        showLowerLMTError(false);
        showUpperLMTError(false);
      }
    }
    const quoteType = electricity.quotes[idx].contentCode;
    const updateQuotes = electricity.quotes.map((quote) => {
      if (quoteType === quote.contentCode) {
        return { ...quote, compRate: rate };
      } else {
        return quote;
      }
    });
    const newElectricity = { ...electricity };
    newElectricity.quotes = updateQuotes;
    setElectricity(newElectricity);
    props.updateRateObj(newElectricity);
  };

  const handleCompEV = (compEV) => {
    const newElectricity = Object.assign({}, electricity);
    if (parseFloat(compEV) > 0.5 || parseFloat(compEV) < 0.0) {
      setError(true);
      newElectricity.compEV = compEV;
      setElectricity(newElectricity);
    } else {
      newElectricity.compEV = compEV;
      setElectricity(newElectricity);
      setError(false);
      props.updateRateObj(newElectricity);
    }
  };

  useEffect(() => {
    setSelectedProduct(props.products[0]?.productDescription);
    setIsPromiseProduct(
      props.products[0]?.productDescription.indexOf("Promise") >= 0
    );
    setIsTOUProd(props.products[0].isTOUPlan === 1);
  }, [props.products]);

  useEffect(() => {
    if (props.userClassList.length > 0) {
      setSelectedUserClass(props.userClassList[0].userClassCode);
    }
  }, [props.userClassList]);

  useEffect(() => {
    const newElectricity = Object.assign({}, electricity);
    newElectricity.quotes = [];
    newElectricity.compEV = "0";
    setElectricity(newElectricity);
  }, [props.utilityChangeObj]);

  useEffect(() => {
    setIsProductOrUserClassChange(false);
    if (props.rate?.myQuote && props.rate.myQuote.length > 0) {
      const quotesArr = [...props?.rate?.myQuote];

      if (quotesArr.filter((quote) => quote.rate === null).length == 0) {
        setIsSeasonal(false);
      } else if (
        quotesArr.filter((quote) => quote.summerRate !== null).length > 0
      ) {
        setIsSeasonal(true);
      }
      const electricity = { ...props.rate };
      electricity.compEV = "";
      electricity.quotes = [...quotesArr];
      setElectricity(electricity);
    }
  }, [props.rate]);

  useEffect(() => {
    setQuaError(props.qaError);
  }, [props.qaError]);

  useEffect(() => {}, [props.pulseTotal]);

  const convertoTODollorStr = (cents) => {
    if (cents !== "" || cents !== null) {
      const dollors = cents.toLocaleString("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      });
      return dollors === undefined ? "" : dollors;
    } else {
      return "";
    }
  };

  const getCompetitorTotal = (quote) => {
    if (quote.quantity && quote.compRate) {
      if (quote.contentCode.startsWith("EG")) {
        return (
          "-" +
          convertoTODollorStr((Number(quote.compRate) * quote.quantity) / 100)
        );
      } else {
        if (quote.contentCode === "CONGESTION") {
          return convertoTODollorStr(
            (quote.compRate *
              quote.quantity *
              (props?.icpDetails?.installDetails
                ? Number(props?.icpDetails?.installDetails)
                : 0)) /
              100
          );
        }

        if (quote.contentCode === "CAPACITY") {
          return convertoTODollorStr(
            (quote.compRate *
              quote.quantity *
              (props?.icpDetails?.chargeableCapacity
                ? Number(props?.icpDetails?.chargeableCapacity)
                : 0)) /
              100
          );
        }
        return convertoTODollorStr(
          (Number(quote.compRate) *
            (quote.quantity /
              (props?.rate?.isCompetitorRateGSTInlcusive ? 1.15 : 1))) /
            100
        );
      }
    }
  };

  const getPulseTotalForRow = (quote) => {
    let total = (quote.rate * quote.quantity) / 100;

    if (quote.contentCode === "CONGESTION") {
      total =
        (quote.rate *
          quote.quantity *
          (props?.icpDetails?.installDetails
            ? Number(props?.icpDetails?.installDetails)
            : 0)) /
        100;
    }

    if (quote.contentCode === "CAPACITY") {
      total =
        (quote.rate *
          quote.quantity *
          (props?.icpDetails?.chargeableCapacity
            ? Number(props?.icpDetails?.chargeableCapacity)
            : 0)) /
        100;
    }

    return convertoTODollorStr(total);
  };

  const getPricePromiseTotalForRow = (quote) => {
    let total = props.fixedDecimals(
      ((Number(quote.rate) + Number(pulseTotal?.pricePromiseAdjustmentValue) ||
        0) *
        quote.quantity) /
        100
    );

    if (quote.contentCode.startsWith("EG") || quote.contentCode === "FIXED") {
      total = props.fixedDecimals(
        (Number(quote.rate) * Number(quote.quantity)) / 100
      );
    } else if (quote.contentCode === "CONGESTION") {
      total = props.fixedDecimals(
        (quote.rate *
          quote.quantity *
          (props?.icpDetails?.installDetails
            ? Number(props?.icpDetails?.installDetails)
            : 0)) /
          100
      );
    } else if (quote.contentCode === "CAPACITY") {
      total = props.fixedDecimals(
        (quote.rate *
          quote.quantity *
          (props?.icpDetails?.chargeableCapacity
            ? Number(props?.icpDetails?.chargeableCapacity)
            : 0)) /
          100
      );
    }

    return total;
  };

  const getAdjustedPulseRate = (quote) => {
    let rate = 0;

    if (quote.contentCode.startsWith("EG") || quote.contentCode === "FIXED") {
      rate = quote?.rate?.toFixed(3);
    } else if (
      quote.contentCode === "CONGESTION" ||
      quote.contentCode === "CAPACITY"
    ) {
      rate = rate = quote?.rate?.toFixed(3);
    } else {
      rate = props.fixedDecimals(
        Number(quote?.rate) + Number(pulseTotal?.pricePromiseAdjustmentValue) ||
          0
      );
    }

    quote["promiseProductRate"] = rate;

    return rate;
  };

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <h3 className="my-3">Electricity Registry</h3>
        <div className="row header-table">
          <div className="col-3 green-strip-bg">
            <h5>Item Name</h5>
          </div>
          <div className="col-3 green-strip-bg">
            <h5>Item Details</h5>
          </div>
          <div className="col-6 green-strip-bg"></div>
        </div>
        <div className="row">
          <div className="col-6">
            <table className="table item-table">
              <tr>
                <td>Product</td>
                <td>
                  <select
                    className="form-control"
                    onChange={(event) => handleProduct(event.target.value)}
                  >
                    {props.products.map((product) => (
                      <option
                        key={product.productCode}
                        value={product.productCode}
                      >
                        {product.productDescription}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>User Class</td>
                <td>
                  <select
                    className="form-control"
                    value={selectedUserClass}
                    onChange={(event) => handleUserClass(event.target.value)}
                  >
                    {props.userClassList.map((userClass) => (
                      <option
                        key={userClass.userClassCode}
                        value={userClass.userClassCode}
                      >
                        {userClass.userClassDescription}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>ICP Status</td>
                <td>{props.icpDetails.icpStatus}</td>
              </tr>
              <tr>
                <td>Network</td>
                <td>{props.icpDetails.network}</td>
              </tr>
              <tr>
                <td>Advanced Infrastructure</td>
                <td>
                  <input
                    type="checkbox"
                    checked={props.icpDetails.advancedInfrastructure === "true"}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <td>Number of Meters</td>
                <td>{props.icpDetails.numberOfMeters}</td>
              </tr>
              <tr>
                <td>Regist Content Code</td>
                <td>{props.icpDetails.registContentCode}</td>
              </tr>
              <tr>
                <td>Current Retailer</td>
                <td>{props.icpDetails.currentRetailerName}</td>
              </tr>
              <tr>
                <td>Address</td>
                <td>{props.icpDetails.address}</td>
              </tr>
            </table>
          </div>
          <div></div>
        </div>
      </div>
      {isTOUProd && (
        <TariffTable
          quotes={electricity.quotes}
          planCode={electricity.planCode}
        />
      )}
      {isTOUProd === false && isProductOrUserClassChange === false && (
        <div className="col-12 mb-4">
          <div className="card shadow">
            <div className="card-header">
              <h5>
                Pulse Electricity Rate{" "}
                {props.rate?.planCode && <>({props.rate.planCode})</>}
              </h5>
            </div>
            <div className="card-body">
              {lowerLMT && (
                <div className="text-danger text-end">
                  The variable charge rate lower limit is:{" "}
                  {props.fixedDecimals(
                    electricity?.pricePromiseParameterAdjustmentVariable
                      ?.varChargeLowerLimit
                  )}
                </div>
              )}
              {upperLMT && (
                <div className="text-danger text-end">
                  The variable charge rate upper limit is:{" "}
                  {props.fixedDecimals(
                    electricity?.pricePromiseParameterAdjustmentVariable
                      ?.varChargeUpperLimit
                  )}
                </div>
              )}
              <table className="table table-bordered">
                <thead className="thead-light">
                  <tr>
                    <th>Content Description</th>
                    <>
                      <th>Pulse Normal Rate (GST Exclusive)</th>
                      {isPromiseProduct && <th>Pulse Rate (Adjusted)</th>}
                    </>

                    {isSeasonal && (
                      <>
                        <th>Summer Rate(GST Exclusive)</th>
                        <th>Winter Rate(GST Exclusive)</th>
                      </>
                    )}
                    <th>Quantity</th>

                    <>
                      <th>Pulse Total ($)</th>
                      {isPromiseProduct && (
                        <>
                          <th>Price Promise Total ($)</th>
                          <th>
                            Competitor Rate (GST{" "}
                            {props?.rate?.isCompetitorRateGSTInlcusive
                              ? "Inclusive"
                              : "Exclusive"}
                            )
                          </th>
                          <th>Competitor Total ($)</th>
                        </>
                      )}
                    </>

                    {isSeasonal && (
                      <>
                        <th>Pulse Summer Total($)</th>
                        <th>Pulse Winter Total($)</th>
                      </>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {electricity?.quotes?.map((quote, i) => {
                    let pulseNormalRateUnit = "Cents";

                    if (quote.contentCode === "CAPACITY") {
                      pulseNormalRateUnit += " kVA/Day";
                    }
                    if (quote.contentCode === "CONGESTION") {
                      pulseNormalRateUnit += " kW/Day";
                    }

                    return (
                      <tr key={i}>
                        <td>{quote?.contentDesc}</td>

                        <>
                          <td>
                            {quote?.rate !== null && (
                              <>
                                {quote?.rate?.toFixed(3)} {pulseNormalRateUnit}
                              </>
                            )}{" "}
                          </td>
                          {isPromiseProduct && (
                            <td>
                              {pulseTotal !== null
                                ? getAdjustedPulseRate(quote)
                                : ""}

                              <>
                                {pulseTotal !== null &&
                                  " " + pulseNormalRateUnit}
                              </>
                            </td>
                          )}
                        </>

                        {isSeasonal && (
                          <>
                            <td>
                              {quote?.summerRate !== null
                                ? quote?.summerRate?.toFixed(3)
                                : ""}
                            </td>
                            <td>
                              {quote?.summerRate !== null
                                ? quote?.winterRate?.toFixed(3)
                                : ""}
                            </td>
                          </>
                        )}
                        <td>
                          <input
                            type="number"
                            id="fixedQuantity"
                            className="form-control"
                            value={quote.quantity || ""}
                            min={0}
                            disabled={
                              quote.contentCode === "CAPACITY" ||
                              quote.contentCode === "CONGESTION"
                                ? true
                                : false
                            }
                            placeholder={
                              quote.contentCode === "FIXED" ||
                              quote.contentCode === "CAPACITY" ||
                              quote.contentCode === "CONGESTION"
                                ? "1 Day"
                                : "1 kWH"
                            }
                            style={{
                              border: quaError ? "1px solid red" : "",
                            }}
                            onChange={(e) => handleQuantity(e.target.value, i)}
                          />
                        </td>

                        <>
                          <td>
                            {quote.quantity && getPulseTotalForRow(quote)}
                          </td>
                          {isPromiseProduct && (
                            <>
                              {}
                              <td>
                                <>{pulseTotal !== null && "$ "}</>
                                {pulseTotal !== null
                                  ? getPricePromiseTotalForRow(quote)
                                  : ""}{" "}
                              </td>
                              <td>
                                <input
                                  type="number"
                                  id="rate"
                                  className="form-control"
                                  value={quote.compRate || ""}
                                  style={{
                                    border: quaError ? "1px solid red" : "",
                                  }}
                                  placeholder="1 Cents"
                                  onChange={(e) =>
                                    handleCompRate(e.target.value, i, quote)
                                  }
                                />
                              </td>

                              <td>{getCompetitorTotal(quote)}</td>
                            </>
                          )}
                        </>

                        {isSeasonal && (
                          <>
                            <td>
                              {quote?.quantity &&
                                convertoTODollorStr(
                                  (quote?.summerRate * quote?.quantity) / 100
                                )}
                            </td>
                            <td>
                              {quote?.quantity &&
                                convertoTODollorStr(
                                  (quote?.winterRate * quote?.quantity) / 100
                                )}
                            </td>
                          </>
                        )}
                      </tr>
                    );
                  })}
                  {isPromiseProduct && (
                    <tr>
                      <td></td>
                      <td></td>

                      <>{isPromiseProduct && <td></td>}</>

                      {isSeasonal && (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}
                      <td></td>
                      <td></td>

                      <>
                        {isPromiseProduct && (
                          <>
                            <td>
                              {" "}
                              {pulseTotal?.pricePromisePulseResult?.pulseEALevy}
                            </td>
                            <td className="text-right">
                              Competitor EA Levy
                              <input
                                type="number"
                                id="fixedQuantity"
                                className="form-control"
                                value={electricity.compEV}
                                style={{ border: error ? "1px solid red" : "" }}
                                min={0}
                                max={0.5}
                                onChange={(e) => handleCompEV(e.target.value)}
                              />
                              {error === true && (
                                <span className="text-danger">
                                  Not a valid levy value.
                                </span>
                              )}
                            </td>
                            <td></td>
                          </>
                        )}
                      </>

                      {isSeasonal && (
                        <>
                          <td></td>
                          <td></td>
                        </>
                      )}
                    </tr>
                  )}
                  <tr>
                    <td></td>
                    <td></td>
                    {isPromiseProduct && <td></td>}

                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    <td className="text-right">Pulse Total (GST Excluded)</td>
                    {isSeasonal && (
                      <>
                        {!isPromiseProduct && (
                          <td>
                            {pulseTotal !== null &&
                              pulseTotal.pulseTotalExclusive &&
                              `$${props.fixedDecimals(
                                pulseTotal.pulseTotalExclusive
                              )}`}
                          </td>
                        )}
                        {isPromiseProduct && (
                          <>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromisePulseResult
                                    ?.pulseTotalGSTExcludedBeforeAdjustment
                                )}`}
                            </td>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromisePulseResult
                                    ?.pulseTotalGSTExcludedAfterAdjustment
                                )}`}
                            </td>
                            <td className="text-right">
                              Competitor Total (GST Excluded)
                            </td>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromiseCompetitorResult
                                    ?.competitorTotalGSTExclusive
                                )}`}
                            </td>
                          </>
                        )}
                      </>
                    )}
                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    {!isSeasonal && (
                      <>
                        {!isPromiseProduct && (
                          <td>
                            {pulseTotal !== null &&
                              pulseTotal.pulseTotalExclusive &&
                              `$${props.fixedDecimals(
                                pulseTotal.pulseTotalExclusive
                              )}`}
                          </td>
                        )}
                        {isPromiseProduct && (
                          <>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromisePulseResult
                                    ?.pulseTotalGSTExcludedBeforeAdjustment
                                )}`}
                            </td>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromisePulseResult
                                    ?.pulseTotalGSTExcludedAfterAdjustment
                                )}`}
                            </td>
                            <td className="text-right">
                              Competitor Total (GST Excluded)
                            </td>
                            <td>
                              {pulseTotal !== null &&
                                `$${props.fixedDecimals(
                                  pulseTotal?.pricePromiseCompetitorResult
                                    ?.competitorTotalGSTExclusive
                                )}`}
                            </td>
                          </>
                        )}
                      </>
                    )}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    {isPromiseProduct && <td></td>}

                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    <td className="text-right">Pulse GST</td>
                    <td>
                      {pulseTotal !== null &&
                        `$${
                          pulseTotal?.pricePromisePulseResult
                            ? pulseTotal?.pricePromisePulseResult
                                ?.pulseGSTBeforeAdjustment
                            : pulseTotal?.pulseGST
                        }`}
                    </td>

                    {isPromiseProduct && (
                      <>
                        <td>
                          {pulseTotal !== null &&
                            `$${pulseTotal?.pricePromisePulseResult?.pulseGSTAfterAdjustment}`}
                        </td>
                        <td className="text-right">Competitor GST</td>
                        <td>
                          {pulseTotal &&
                            `$${pulseTotal?.pricePromiseCompetitorResult?.competitorGST}`}
                        </td>
                      </>
                    )}
                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                  <tr>
                    <td></td>
                    <td></td>
                    {isPromiseProduct && <td></td>}

                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                    <td className="text-right">Pulse Total (GST Included)</td>

                    {!isPromiseProduct && (
                      <td>
                        {pulseTotal !== null &&
                          pulseTotal?.pulseTotalGSTInclusive &&
                          `$${props.fixedDecimals(
                            pulseTotal.pulseTotalGSTInclusive
                          )}`}
                      </td>
                    )}
                    {isPromiseProduct && (
                      <>
                        <td>
                          {pulseTotal !== null &&
                            `$${props.fixedDecimals(
                              pulseTotal?.pricePromisePulseResult
                                ?.pulseTotalGSTInclusiveBeforeAdjustment
                            )}`}
                        </td>
                        <td>
                          {pulseTotal !== null &&
                            `$${props.fixedDecimals(
                              pulseTotal?.pricePromisePulseResult
                                ?.pulseTotalGSTInclusiveAfterAdjustment
                            )}`}
                        </td>
                        <td className="text-right">
                          Competitor Total (GST Included)
                        </td>
                        <td>
                          {pulseTotal !== null &&
                            `$${props.fixedDecimals(
                              pulseTotal?.pricePromiseCompetitorResult
                                ?.competitorTotalGSTInclusive
                            )}`}
                        </td>
                      </>
                    )}
                    {isSeasonal && (
                      <>
                        <td></td>
                        <td></td>
                      </>
                    )}
                  </tr>
                  {isPromiseProduct && (
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td>Price Promise:</td>
                      <td>
                        {pulseTotal?.formattedPricePromiseAdjustmentString}
                      </td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  )}
                </tbody>
              </table>
              {quaError && (
                <div className="text-danger text-center">
                  Quantity should not be blank.
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ElectricityRegistry;
