import React from "react";
import ReactDOM from "react-dom/client";
import ElectricityRegistry from "./electricityRegistry";
import GasRegistry from "./gasRegistry";

const Header = () => {
  return (
    <div className="container-fluid">
      <div className="row m-4 header-logo">
        <div className="col-8">
          <img src="icons/logo.png" alt="Pulse Energy" className="logo" />
        </div>
        <div className="col-2">
          <img src="icons/help.png" alt="Pulse Energy" className="help" />
          <img src="icons/profile.png" alt="Pulse Energy" className="profile" />
        </div>
      </div>
    </div>
  );
};

export default Header;
