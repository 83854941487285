import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const GasRegistry = (props) => {
  const [kwQuantity, setKWQuantity] = useState();
  const [fixedQuantity, setFixedQuantity] = useState();

  const [compRate, setCompRate] = useState();
  const [compRate24, setCompRate24] = useState();

  const [compEV, setCompEV] = useState(0);

  const [fixedRate, setFixedRate] = useState({});
  const [unRate, setUNRate] = useState({});
  const [otherRate, setOtherRate] = useState({});

  const [selectedProduct, setSelectedProduct] = useState("");

  const [quaError, setQuaError] = useState(false);
  const [kwError, setKwError] = useState(false);

  const [isPromiseProduct, setIsPromiseProduct] = useState(false);

  const [gasQuotes, setGasQuotes] = useState([]);

  let pulseTotal =
    props.pulseTotal !== null ? Object.assign({}, props.pulseTotal) : null;

  const handleProduct = (productCode) => {
    pulseTotal = null;
    const product = props.products.find(
      (product) => product.productCode === productCode
    );
    // setSelectedProduct(product.productDescription);
    //  setIsPromiseProduct(product.productDescription.indexOf('Promise') >= 0);
    props.passProductData(product);
  };

  const handleUserClass = (userClassCode) => {
    props.passUserClassData(userClassCode);
  };

  useEffect(() => {
    setSelectedProduct(props?.products[0]?.productDescription);
    // setIsPromiseProduct(props?.products[0]?.productDescription.indexOf('Promise') >= 0)
    setIsPromiseProduct(false);
  }, [props.products]);

  useEffect(() => {
    if (props?.rate?.myGasQuote && props.rate.myGasQuote.length > 0) {
      const gasQuotesArr = [...props?.rate?.myGasQuote];

      // Track the first occurrence of each code
      const seenCodes = new Set();

      const result = gasQuotesArr.map((item) => {
        if (!seenCodes.has(item.contentCode)) {
          seenCodes.add(item.contentCode);
          return { ...item, change: true };
        }
        return item;
      });
      setGasQuotes(result);
    }
  }, [props.rate]);

  useEffect(() => {
    setQuaError(props.qaError);
  });

  const convertoTODollorStr = (cents) => {
    const dollors = cents.toLocaleString("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 3,
      maximumFractionDigits: 3,
    });
    return dollors === undefined ? "" : dollors;
  };

  const handleQuantity = (quantity, idx) => {
    const quoteType = gasQuotes[idx].contentCode;
    const updateQuotes = gasQuotes.map((quote) => {
      if (quoteType === quote.contentCode) {
        return { ...quote, quantity: quantity };
      } else {
        return quote;
      }
    });
    setGasQuotes(updateQuotes);
    props.updateRateObj(updateQuotes);
  };

  useEffect(() => {
    setGasQuotes([]);
  }, [props.utilityChangeObj]);

  return (
    <div className="row">
      <div className="col-12 mb-4">
        <h3 className="my-3">Gas Registry</h3>
        <div className="row header-table">
          <div className="col-3 green-strip-bg">
            <h5>Item Name</h5>
          </div>
          <div className="col-3 green-strip-bg">
            <h5>Item Details</h5>
          </div>
          <div className="col-6 green-strip-bg"></div>
        </div>
        <div className="row">
          <div className="col-6">
            <table className="table item-table">
              <tr>
                <td>Product</td>
                <td>
                  <select
                    className="form-control"
                    onChange={(event) => handleProduct(event.target.value)}
                  >
                    {props.products.map((product) => (
                      <option
                        key={product.productCode}
                        value={product.productCode}
                      >
                        {product.productDescription}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
              <tr>
                <td>User Class</td>
                <td>
                  <select
                    className="form-control"
                    onChange={(event) => handleUserClass(event.target.value)}
                  >
                    {props.userClassList.map((userClass) => (
                      <option
                        key={userClass.userClassCode}
                        value={userClass.userClassCode}
                      >
                        {userClass.userClassDescription}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </table>
          </div>
          <div></div>
        </div>
      </div>

      <div className="col-12 mb-4">
        <div className="card shadow">
          <div className="card-header">
            <h5>Pulse Gas Rate</h5>
          </div>
          <div className="card-body">
            <table className="table table-bordered">
              <thead className="thead-light">
                <tr>
                  <th>Content Description</th>
                  <th>Pulse Normal Rate (GST Exclusive)</th>
                  <th>Quantity</th>
                  <th>Pulse Total ($)</th>
                </tr>
              </thead>
              <tbody>
                {gasQuotes.map((quote, i) => {
                  return [
                    <tr>
                      <td>{quote.contentDesc}</td>
                      <td>{quote.rate.toFixed(3)} Cents</td>
                      <td>
                        {quote.change !== undefined ? (
                          <input
                            type="text"
                            id="fixedQuantity"
                            className="form-control"
                            value={quote.quantity}
                            min={0}
                            style={{ border: quaError ? "1px solid red" : "" }}
                            placeholder={
                              quote.contentCode === "FIXED" ? "1 Day" : "1 kWH"
                            }
                            onChange={(e) => {
                              handleQuantity(e.target.value, i);
                            }}
                          />
                        ) : (
                          <td></td>
                        )}
                      </td>
                      <td>
                        {quote.quantity &&
                          convertoTODollorStr(
                            (quote.rate * quote.quantity) / 100
                          )}
                      </td>
                    </tr>,
                  ];
                })}
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">Pulse EVA Levy</td>
                  <td> N/A </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">Pulse Total (GST Excluded)</td>
                  <td>
                    {pulseTotal &&
                      pulseTotal?.gasSaving?.pulseTotalExclusive &&
                      `$${props.fixedDecimals(
                        pulseTotal?.gasSaving?.pulseTotalExclusive
                      )}`}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">Pulse GST</td>
                  <td>
                    {pulseTotal !== null &&
                      `$${pulseTotal && pulseTotal?.gasSaving?.pulseGST}`}
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>
                  <td className="text-right">Pulse Total (GST Included)</td>
                  <td>
                    {pulseTotal &&
                      pulseTotal?.gasSaving?.pulseTotalGSTInclusive &&
                      `$${props.fixedDecimals(
                        pulseTotal?.gasSaving?.pulseTotalGSTInclusive
                      )}`}
                  </td>
                </tr>
              </tbody>
            </table>
            {quaError && (
              <div className="text-danger text-center">
                Quantity should not be blank.
              </div>
            )}
          </div>
        </div>
        {/* <div className='card shadow mt-2'>
                        <div className='card-header'>
                            <h5>Grand Total</h5>
                        </div>
                        <div className='card-body'>
                            <div className="row text-center">
                                {!isPromiseProduct && <div className="col-md-4">Pulse Total ($) : {(pulseTotal !== null && pulseTotal?.pulseTotalGSTInclusive) && `${pulseTotal?.pulseTotalGSTInclusive}`}</div>}
                                {isPromiseProduct && <div className="col-md-4">Pulse Total ($) : {pulseTotal !== null && `${pulseTotal?.pricePromisePulseResult?.pulseTotalGSTInclusiveAfterAdjustment}`}</div>}

                                { isPromiseProduct && <div className="col-md-4">Competitor Total ($): {pulseTotal?.pricePromiseCompetitorResult?.competitorTotalGSTExclusive}</div> }
                                <div className="col-md-4">Savings ($): {(pulseTotal !== null && pulseTotal?.formattedSavingString) && `${pulseTotal?.formattedSavingString}`}</div>
                        
                            </div>
                            <div className="col-12 text-center my-4">
                                <button className="btn btn-success btn-lg" onClick={handleCalculateTotal}>Calculate Total</button>
                            </div>
                        </div>
                    </div> */}
      </div>
    </div>
  );
};

export default GasRegistry;
