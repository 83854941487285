import React, { useState, useEffect } from "react";

const TariffTable = (props) => {
  const [tariffData, setTariffData] = useState([]);

  useEffect(() => {
    console.log(props.quotes);
    setTariffData(props.quotes);
  }, [props.quotes]);

  return (
    <div className="tariff-table-container">
      <div className="card-header">
        <h5>
          Pulse Electricity Rate {props?.planCode && <>({props?.planCode})</>}
        </h5>
      </div>
      <table className="tariff-table">
        <thead>
          <tr>
            <th>Content Desc</th>
            <th>Charge Desc</th>
            <th>Rate in cents (excluding GST)</th>
            <th>Billing Unit</th>
          </tr>
        </thead>
        <tbody>
          {tariffData &&
            tariffData.map((row, index) => (
              <tr key={index}>
                <td>{row?.contentDesc}</td>
                <td>{row?.chargeDesc}</td>
                <td>{row?.rate?.toFixed(4) || ""}</td>
                <td>{row?.contentCode === "FIXED" ? "per day" : "per kWh"}</td>
              </tr>
            ))}
        </tbody>
        {/* <tfoot>
            <tr className='green-strip-bg'>
                <td></td>
                  <td></td>
                  <td>Daily</td>
                  <td>Once Per Day</td>
                  <td>Daily Fixed Charges</td>
                  <td></td>
                  <td>Per Day</td>
              </tr>
          </tfoot> */}
      </table>
    </div>
  );
};

export default TariffTable;
